import React, {useEffect, useState} from 'react';
import Copyright from "../components/Copyright";
import QRCode from 'qrcode.react';
import {Box, Container, createTheme, CssBaseline, Grid, ThemeProvider} from "@mui/material";

const theme = createTheme({
    palette: {
        primary: {
            main: '#f44336',
        },
        text: {
            primary: '#FFFFFF',
        },
        background: {
            default: '#FFA500',
        },
    },
});
const DownloadPage = () => {
    const googlePlayURL = 'https://play.google.com/';
    const appStoreURL = 'https://apps.apple.com/';

    const [width, setWidth] = useState<number>(window.innerWidth);

    function handleWindowSizeChange() {
        setWidth(window.innerWidth);
    }

    useEffect(() => {
        window.addEventListener('resize', handleWindowSizeChange);
        return () => {
            window.removeEventListener('resize', handleWindowSizeChange);
        }
    }, []);

    const isMobile = width <= 768;

    let qrCodeSize = isMobile ? 12 : 6;
    let qrCodeSpacing = isMobile ? 10 : 2;

    const openInNewTab = (url: string) => () => {
        window.open(url, "_blank", "noreferrer");
    };


    return (
        <ThemeProvider theme={theme}>
            <Container component="main">
                <CssBaseline/>

                <div style={{textAlign: 'center'}}>
                    <h1>Groovy Cart</h1>
                    <p>Your one-stop shop for all things groovy!</p>
                    <Grid container spacing={qrCodeSpacing} alignItems={"center"}>
                        <Grid item xs={qrCodeSize}>
                            <Box display="flex" flexDirection="column" alignItems="center" gap={2}>
                                <QRCode value={appStoreURL}
                                        bgColor="#FFA500"
                                        imageSettings={{
                                            src: "./appleStore.png",
                                            height: 64,
                                            width: 64,
                                            excavate: true
                                        }}
                                        size={256}/>
                                <img src={"./appleStoreBadge.png"} style={{width: 200}} alt="App Store Badge" onClick={openInNewTab(appStoreURL)}/>
                            </Box>
                        </Grid>

                        <Grid item xs={qrCodeSize}>
                            <Box display="flex" flexDirection="column" alignItems="center" gap={2}>
                                <QRCode value={googlePlayURL} bgColor="#FFA500"
                                        imageSettings={{
                                            src: "./googlePlay.png",
                                            height: 64,
                                            width: 64,
                                            excavate: true
                                        }}
                                        size={256}/>
                                <img src={"./googlePlayBadge.png"} style={{width: 200}} alt="Google Play Badge" onClick={openInNewTab(googlePlayURL)}/>
                            </Box>
                        </Grid>
                    </Grid>
                </div>
                <Copyright sx={{mt: 8, mb: 4}}/>
            </Container>
        </ThemeProvider>
    );
};

export default DownloadPage;
