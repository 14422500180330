import {DELETE_ME} from "./constants";
import {toast} from "react-toastify";
import {handleErrorBoolean} from "./common";

const deleteProfile = (token: string): Promise<boolean> => {
    return fetch(DELETE_ME, {
        method: 'DELETE',
        headers: {
            'Authorization': `Bearer ${token}`,
        },
    })
        .then((response) => {
            if (response.ok) {
                return true;
            } else {
                toast.error(`Failed to delete profile.`);
                return false;
            }
        })
        .catch(handleErrorBoolean);
};

export {deleteProfile}