import {toast} from "react-toastify";
import {LOGIN} from "./constants";
import {handleError} from "./common";
import {LoginBody, TokenResponse} from "./classes";

const login = (username: string, password: string): Promise<TokenResponse> => {

    let loginBody = new LoginBody();
    loginBody.username = username;
    loginBody.password = password;

    return fetch(LOGIN, {
        method: 'POST',
        body: JSON.stringify(loginBody),
        headers: {
            'Content-Type': 'application/json',
        },
    })
        .then((response) => {
            if (response.ok) {
                return response.json()
            } else {
                toast.error("Failed to log in.")
                return null;
            }
        })
        .catch(handleError);
};

export {login}