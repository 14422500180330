import React from 'react';
import {BrowserRouter, Route, Routes} from 'react-router-dom'
import './App.css';
import NotFoundPage from "./pages/NotFoundPage";
import TermsAndConditions from "./pages/TermsAndConditions";
import DownloadPage from "./pages/DownloadPage";
import HomePage from "./pages/HomePage";
import DeleteMe from "./pages/DeleteMe";
import Privacy from "./pages/Privacy";
import {ToastContainer} from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';

function App() {
    return (
        <BrowserRouter>
            <Routes>
                <Route path={"/"} element={<HomePage/>}/>
                <Route path={"/terms-and-conditions"} element={<TermsAndConditions/>}/>
                <Route path={"/privacy"} element={<Privacy/>}/>
                <Route path={"/download"} element={<DownloadPage/>}/>
                <Route path={"/delete-me"} element={<DeleteMe/>}/>
                <Route path={"/*"} element={<NotFoundPage/>}/>
            </Routes>
            <ToastContainer position={"bottom-left"} theme={"colored"}/>
        </BrowserRouter>
    );
}

export default App;
