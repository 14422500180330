import React from 'react';
import Copyright from "../components/Copyright";

const TermsAndConditions = () => {

    let phoneNumber = "0740695882";
    let primaryEmail = "romwaysoftware@gmail.com"
    let secondaryEmail = "contact@groovycart.ro"
    let website = <a href="https://groovycart.ro">https://groovycart.ro</a>
    let brutariaadoniWebsite = <a href="https://groovycart.ro">https://groovycart.ro</a>

    return (
        <>
            <div style={{width: "80%", marginLeft: "10%"}}>
                <h1 style={{textAlign: 'center'}}>
                    Terms and Conditions
                </h1>
                <h2 style={{textAlign: 'center'}}>
                    Termene și condiții de utilizare generale a site-ului și aplicațiilor precum și de Contractare cu Romway
                </h2>

                <p style={{textIndent: "25px"}}>
                    Prezentele termene și condiții de utilizare generale ( denumite în continuare, „Condiții generale
                    sau Document”) se aplică site-ului web al ROMWAY TRADE SRL (în continuare, „Romway”), al cărui
                    domeniu este {website}, și la aplicația mobilă aferentă, precum și la toate site-urile
                    sale conexe sau la site-urile legate de {brutariaadoniWebsite} aparținând Romway, precum și
                    companiilor sale afiliate sau asociate. Site-ul și aplicația aparțin exclusiv societății ROMWAY TRADE SRL.
                </p>


                <p style={{textIndent: "25px"}}>
                    Prezentele termene și condiții sunt puse la dispoziția comercianților, mandatarilor și utilizatorilor site-ului și aplicației proprietatea
                    Romway care vor fi denumiți în continuare Utilizatorul, Comerciant-Colaborator, Mandatar/Curier iar site-ul și aplicația mobilă vor fi
                    denumite în cadrul termenelor „Aplicația”.
                </p>

                <p style={{textIndent: "25px"}}>
                    Acestea cuprind suma normelor si procedurilor care reglementează atât site-ul și aplicația proprietatea Romway Trade SRL cât si contractul
                    care guvernează relația dintre Utilizator si Romway, Utilizator- Comerciant, utilizator-Mandatar, fiind acceptați de către toate părțile
                    contractante, odată cu înregistrarea pe site și/sau în aplicație prin bifarea opțiunii “Sunt de acord cu Termenii și Condițiile de utilizare
                    a GroovyCart.”
                </p>


                <p>
                    <b>
                        ROMWAY TRADE SRL este o persoană juridică română, cu sediul social în Sat Adoni, Comuna Tarcea, Nr.
                        273, Județ Bihor, înregistrată la Registrul Comerțului sub număr J05/312/1996 CUI 8274826 e-mail
                        {secondaryEmail} si {primaryEmail}, telefon {phoneNumber}.
                    </b>
                </p>

                <p style={{textIndent: "25px"}}>
                    <strong>Definiții termeni și expresii utilizate în cele ce urmează:</strong>
                </p>

                <ol>
                    <li>
                        <strong>Utilizator
                        </strong>– orice persoană fizică cu vârsta de 18 ani împliniți sau persoana juridică, care are sau obține acces pe site sau în aplicație
                        în baza unui acord de utilizare existent între Romway și aceștia și care necesită înregistrarea pe site-ul și/sau în aplicația Romway.
                    </li>
                    <li>
                        <strong>Comerciant-Colaborator – Partener
                        </strong>- persoana fizică sau juridică care comercializează produse sau servicii, care îsi publică si promoveaza produsele ți/sau
                        serviciile prin intermediul site-ului/aplicației Romway în scopul vânzării acestora;
                    </li>
                    <li>
                        <strong>Mandatar- curier -</strong>persoanele care prestează serviciile de livrare a produselor comandate de utilizatori și cu care
                        Romway a încheiat contracte
                        privind furnizarea serviciilor de livrare necesare pentru a asigura livrarea comenzilor către Utilizatori, în cazul în care
                        Comerciantul-Colaboratorul nu are sau nu dorește să folosească serviciul propriu de livrare
                    </li>
                    <li>
                        <strong>Romway –</strong>este denumirea comercială a societății ROMWAY TRADE SRL, persoană juridică română, cu sediul social în Sat
                        Adoni, Comuna
                        Tarcea, Nr. 273, Județ Bihor, înregistrată la Registrul Comerțului sub număr J05/312/1996 CUI 8274826 e-mail {primaryEmail}
                        telefon {phoneNumber}
                    </li>
                    <li>
                        <strong>Înregistrare-Cont -</strong>
                        secțiunea din site-aplicație formată dintr-o adresă de e-mail și o parola care permite Utilizatorului transmiterea Comenzii si care
                        contine informatii despre Utilizator si istoricul activității acestuia pe site și în aplicație.
                    </li>
                    <li>
                        <strong>Consimțământ –</strong>
                        acord al persoanei vizate înseamnă orice manifestare de voință liberă, specifică, informată și lipsită de ambiguitate a persoanei vizate
                        prin care aceasta acceptă, printr-o declarație sau printr-o acțiune fără echivoc ca datele cu caracter personal care o privesc și care
                        au fost furnizate prin înregistrare-plasarea comenzilor să fie prelucrate.Prin accesarea site-ului și a aplicației respectiv
                        înregistrarea, toți Utilizatorii recunosc și acceptă în mod expres și fără ambiguitate prezenții Termeni si conditii, precum și politica
                        de confidențialitate și politica privind fișierele cookie.
                    </li>
                    <li>
                        <strong>Coșul meu</strong>
                        – acea secțiune din profilul înregistrat de utilizator care permite acestuia adăugarea produselor pe care doreste sa le achizitioneze în
                        acel moment sau la un moment ulterior.
                    </li>
                    <li>
                        <strong>Site
                        </strong>- magazin online cu produse si servicii accesibil la adresa Strada Vörösmarty Mihály 93, Valea lui Mihai.
                    </li>
                    <li>
                        <strong>Aplicație
                        </strong>- aplicație destinatî terminalelor mobile, care rulează site-ul și care facilitează plasarea comenzilor;
                    </li>
                    <li>
                        <strong>Comunicări Comerciale -</strong>
                        orice tip de mesaj trimis ce conține informații tematice cu privire la produse similare sau complementare cu cele cumpărate prin
                        intermediul aplicației, informații cu privire la ofertele Romway și/sau referitoare la produsele adăugate în sectiunea “Coșul meu” sau
                        “Favorite” precum și alte comunicari comerciale.
                    </li>
                    <li>
                        <strong>Garantie SGR</strong>
                        - suma plătită de către Utilizator către Colaboratorul Romway la achiziționarea unui produs ambalat în ambalaj SGR, sumă ce se achită
                        separat de prețul produsului şi care este restituită integral Utilizatorului la momentul returnării ambalajului SGR în cadrul unui punct
                        de lucru al comerciantului.
                    </li>
                    <li>
                        <strong>Bunuri si servicii -</strong>
                        orice produs sau serviciu listat pe site-aplicație inclusiv produsele și serviciile mentionate în Comandă care urmeaza a fi furnizate de
                        catre Comercianți Utilizatorului ca urmare a Contractului încheiat;
                    </li>
                    <li>
                        <strong>Contract</strong>
                        - actul încheiat la distanță între Romway si/sau colaboratorii săi cu Utilizatorul, care nu necesita prezenta fizică simultană a
                        celor două părti.
                    </li>
                    <li>
                        <strong>Preț de vânzare -</strong>
                        contravaloarea Bunului sau Serviciului solicitată de Comerciant Utilizatorului indicate pe site- aplicație. Pretul de Vanzare este
                        stabilit în mod unilateral de către Colaborator.
                    </li>
                </ol>
                <p>
                    <strong>În cadrul prezentului document vom regăsi următoarele ”Capitole”:</strong> <br/>
                    1. Descrierea societății și activității <br/>
                    2. Înregistrarea și utilizarea aplicației3. Funcționarea aplicației. Plasarea comenzilor <br/>
                    4. Prețurile produselor. Costul transportului. Modalități de plată. Tips. Comisioane. Facturare <br/>
                    5. Modalitatea de anularea comenzilor și dreptul de retragere din contract. Returul <br/>
                    6. Politica privind livrarea produselor interzise <br/>
                    7. Geolocație8. Responsabilități și Garanții <br/>
                    9. Prelucrarea datelor cu caracter personal si confidentialitatea informatiilor <br/>
                    10 Proprietate intelectuală <br/>
                    11. Clauza de separabilitate <br/>
                    12. Diverse. Litigii. Forța Majoră <br/>
                </p>

                <p>
                    <strong>1. OBIECT</strong>
                </p>
                <p style={{textIndent: "25px"}}>1.1. Romway este o companie a cărei activitate principală constă în COD CAEN 1071, precum și gestionarea unei
                    platforme tehnologice prin intermediul căreia unele magazine locale cu profil alimentar din anumite orașe să își ofere produsele și/sau
                    serviciile în cadrul Aplicației și, în mod accesoriu, dacă este cazul și dacă este solicitat prin intermediul Aplicației de către
                    Utilizatorii Aplicației și de către consumatorii magazinelor locale menționate, să acționeze ca intermediar în livrarea planificată sau
                    imediată a produselor de către terți. Când doresc să își furnizeze serviciile, Comercianții și Mandatarii se înregistrează în Aplicație și
                    se angajează să îndeplinească serviciul încredințat acestora de către Utilizator într-o anumită perioadă.
                </p>
                <p style={{textIndent: "25px"}}>1.2.Prin accesarea Site-ului și/sau a Aplicației
                    respectiv
                    prin crearea voluntară a unui profil, toți Utilizatorii, Comercianții și Mandatarii recunosc și sunt de acord în mod expres și neechivoc cu
                    acești Termeni Generali, precum și cu Politica de Confidențialitate și Politica cookies disponibile online. Orice Utilizator, Comerciant
                    și/sau Mandatar care nu este de acord cu acești Termeni și/sau Politici generale trebuie să se abțină de la utilizarea acestora.</p>
                <p style={{textIndent: "25px"}}>
                    <strong>2. ACCESUL ȘI ÎNREGISTRAREA UTILIZATORULUI</strong>
                </p>
                <p style={{textIndent: "25px"}}>2.1.Pentru a fi un Utilizator al Aplicației, fiecare persoană trebuie să îndeplinească următoarele
                    condiții: </p>
                <p style={{textIndent: "50px"}}>• Să aibă minim 18 ani împliniți; </p>
                <p style={{textIndent: "50px"}}>• Să completeze în integralitate formularul de înregistrare;</p>
                <p style={{textIndent: "50px"}}>• Să citească și să accepte Termenii generali, Politica de confidențialitate și Politica de protecție a
                    datelor,
                    Politica cookies, anterior înregistrării și de fiecare data când accesează Aplicația. </p>
                <p style={{textIndent: "25px"}}>
                    2.2. Odată îndeplinite toate cele de mai sus Romway va
                    furniza Utilizatorului confirmarea înregistrării, împreună cu orice alte informații necesare în conformitate cu legislația aplicabilă.</p>
                <p style={{textIndent: "25px"}}>2.3.Toți Utilizatorii care clasificați în conformitate cu legislația în vigoare „consumatori”
                    beneficiază și sunt protejați de toate legile și reglementările relevante privind protecția consumatorilor atunci când utilizează
                    Aplicația.</p>
                <p style={{textIndent: "25px"}}>2.4. Numele de utilizator și parola stabilite la înregistrare sunt strict confidențiale și
                    netransferabile.
                    Romway nu garantează utilizatorilor securitatea contului sau a profilului. 2.5. Romway nu salvează informațiile de plată ale Utilizatorilor
                    acestea urmând a fi procesate și posibil stocate de furnizorul serviciilor de plăți.</p>
                <p style={{textIndent: "25px"}}>2.6. Pin înregistrarea pe site și în aplicație utilizatorul declară că toate informațiile privind
                    identitatea sa și toate datele indicate Romway corespund realității. Dacă există suspiciuni de informații false, incorecte sau incomplete
                    Romway își rezervă dreptul de a limita sau refuza accesul utilizatorului pe site sau în aplicație. Totodată Utilizatorii sunt
                    responsabili pentru furnizarea credențialelor către terțe pesoane. Romway nu garantează identitatea Utilizatorilor înregistrați și nu
                    își asumă nicio responsabilitatea pentru utilizarea identității unui Utilizator înregistrat de către terțe părți neînregistrate. În situația
                    în care se ivesc probleme de furt date access, pierdere sau divulgare credențiale Utilizatorii se obligă să înștiințeze de îndată Romway
                    prin intermediul canalelor de comunicare existente pe site și/sau în aplicație. </p>
                <p style={{textIndent: "25px"}}> 2.7. Utilizatorii nu au dreptul de a folosi profilul
                    înregistrat pe site și/sau în aplicație și niciun alt conținut al acestora în scopuri sau pentru obținere de rezultate ilegale </p>
                <p style={{textIndent: "25px"}}>2.8.
                    Orice persoane care încalcă obligațiile de mai sus vor fi direct responsabile pentru orice pierdere sau daună provocată de către acestea.
                    Romway nu își asumă nicio răspundere pentru eventuale consecințe, ce poate apărea din utilizarea sau accesul ilegal de către terți.</p>
                <p style={{textIndent: "25px"}}>2.9. Se interzice utilizatorilor :</p>
                <p style={{textIndent: "50px"}}>• să altereze și/sau să modifice site-ului și Aplicației, complet sau parțial,;</p>
                <p style={{textIndent: "50px"}}>• să încalce drepturilor de proprietate industrială și intelectuală sau regulile privind legislația de
                    protecție a datelor cu caracter personal;</p>
                <p style={{textIndent: "50px"}}>• să folosească Aplicația pentru a insulta, defăima, intimida sau hărțui alți Utilizatori sau de a le ataca
                    imaginea;</p>
                <p style={{textIndent: "50px"}}>• să acceseze conturile aparținând altor Utilizatori;</p>
                <p style={{textIndent: "50px"}}>• să se abțină de la a face reclamă bunurilor sau serviciilor fără acordul prealabil al Romway. </p>
                <p style={{textIndent: "50px"}}>
                    • orice
                    Utilizator
                    are dreptul de a raporta alt Utilizator dacă acesta consideră că cel din urmă încalcă regulile site-ului și/sau aplicației.</p>
                <p style={{textIndent: "25px"}}>2.10. Utilizatorii pot proceda oricând la ștergerea contului creat prin accesarea secțiunii destinată
                    ștergerii/anulării/dezabonării.</p>
                <p style={{textIndent: "25px"}}>2.11. Romway își rezervă dreptul de a suspenda și/sau de a anula conturile oricărui Utilizator dacă acesta din
                    urmă nu respectă condițiile impuse pentru utilizarea site-ului și nici legislația aplicabilă precum și în cazul unei suspiciuni
                    rezonabile de utilizare frauduloasă sau ilicită.</p>
                <p style={{textIndent: "25px"}}>
                    <strong>3. MODALITATEA DE UTILIZARE A APLICAȚIEI. PLASAREA COMENZILOR</strong>
                </p>
                <p style={{textIndent: "25px"}}>3.1. Utilizatorii pot cumpăra produse și/sau servicii direct de la micii comercianți având posibilitatea de
                    a solicita ridicarea din magazine sau livrarea produsului, de către Mandatari, de la adresele Comercianților la adresa stabilită
                    de Utilizator.</p>
                <p style={{textIndent: "25px"}}>3.2. Utilizatorii pot plasa comenzi doar dacă sunt stabiliți în aria teritorială alocată activității Romway,
                    arie
                    stabilită prin prezentele termene și condiții ca fiind:</p>
                <p style={{textIndent: "50px"}}>- Valea lui Mihai</p>
                <p style={{textIndent: "25px"}}>3.3. Utilizatorul poate efectua Comenzi prin adăugarea Produselor și/sau Serviciilor dorite în coșul de
                    cumpărături, prin selectarea metodei de plată dorite. Adăugarea Produsului și/sau Serviciului în coșul de cumpărături fără a finaliza
                    comanda atrage neînregistrarea comenzii si implinicit nici rezervarea automata a Produsului/Serviciului.</p>
                <p style={{textIndent: "25px"}}>3.4. Utilizatorul cunoaște și acceptă că descrierile, prețurile și fotografiile produselor
                    disponibile pe
                    site și în Aplicație nu sunt proprietatea Romway ci sunt furnizate de Comercianții colaboratori.</p>
                <p style={{textIndent: "25px"}}>3.5. Romway nu asigură nicio garanție cu privire la eventuale necorelări de prețuri și/sau de aspect, de
                    calitate, cu privire la caracteristici, la conținut, descriere, eventualele reclamații urmând a fi adresate direct comerciantului furnizor
                    al produselor.</p>
                <p style={{textIndent: "25px"}}>3.6. Utilizatorul acceptă că toate produsele ce apar în Aplicație sunt supuse disponibilității achiziției și,
                    în
                    acest sens, acceptă posibilitatea ca în timpul completării unei comenzii, produsul să nu mai fie pe stoc.</p>
                <p style={{textIndent: "25px"}}>3.7. Utilizator cunoaște și acceptă că produsele achiziționate/serviciile achiziționate prin intermediul
                    aplicației sunt disponibile numai consumului propriu, Utilizatorul declarând odată cu plasarea comenzii, pe propria răspundere câ
                    acestea nu sunt achiziționate în scop comercial, pentru revânzare.</p>
                <p style={{textIndent: "25px"}}>3.8. Romway își rezervă dreptul de a modifica toate informațiile existente pe site/în aplicație în mod
                    unilateral, în funcție de solicitările comercianților colaboratori și nu își asumă nicio responsabilitate față de utilizator cu privire la
                    modificări, Utilizatorul având obligația de a studia la fiecare comandă toate informațiile produselor.</p>
                <p style={{textIndent: "25px"}}>3.9. Pentru plasarea unei comenzi care conține produse pentru care legislația în vigoare impune vârsta minimă
                    de
                    18 ani, în vederea achiziționarii acestora, Utilizatorul declară pe propria răspundere că respectă prevederile legale, la momentul plasării
                    comenzii. Romway are dreptl de a verifica prin intermediul Colaboratorilor, Mandatarilor respectarea prevederilor legale de către
                    Utilizator, inclusiv în momentul livrării. În situatia constatării nerespectării prevederilor legale in vigoare, comanda se poata anula.
                    Prin finalizarea Comenzii, Utilizatorul consimte ca toate datele furnizate de acesta, necesare procesului de cumpărare, sunt corecte,
                    complete și reale la data și ora plasării Comenzii. Odată cu finalizarea Comenzii, Utilizatorul consimte că Romway sau Colaboratorul poate
                    să îl contacteze, prin orice mijloc disponibil/agreat de aceștia din urmă și în orice situatie în care este necesara contactarea
                    Utilizatorului.</p>
                <p style={{textIndent: "25px"}}>
                    <strong>3.10. Utilizatorii au opțiunea de a comanda, CU RIDICARE DIN MAGAZIN</strong> o selecție de produse oferite de anumiți Comercianți.
                    Aceste opțiuni le permit Utilizatorilor să colecteze ei înșiși fizic comenzile de la
                    unitățile Comercianților. Dar această opțiune este disponibilă doar unităților pentru care această opțiune a fost activată de către
                    comerciantul colaborator</p>
                <p style={{textIndent: "25px"}}>3.11. Opțiunea de ridicare din magazin se va selecta în momentul plasării comenzii de către utilizator.
                    Anularea
                    comenzii cu opțiunea de ridicare din magazin selectată poate fi efectuată doar anterior acceptării/confirmării comenzii de către
                    comerciantul-colaborator. Confirmarea comenzii se realizează în momentul în care Utilizatorul primește e-mailul de confirmare.</p>
                <p style={{textIndent: "25px"}}>3.12. Plata comenzilor cu opțiunea ridicare din magazin se efectuează în momentul plasării comenzii, prin
                    plata
                    cu cardul bancar sau prin plata în numerar la casiera comerciantului.</p>
                <p style={{textIndent: "25px"}}>3.13. Valoarea comenzii nu include la opțiunea ridicare din magazin costuri de transport dar poate include
                    costuri de manipulare, acestea fiind diferite de la un comerciant la altul.</p>
                <p style={{textIndent: "25px"}}>3.14. Pentru ridicarea comenzii din magazin Utilizatorul trebuie să prezinte comerciantului numărul comenzii
                    transmis prin e-mail Utilizatorului.</p>
                <p style={{textIndent: "25px"}}>
                    <strong>3.15. Comenzile cu livrare prin curier /mandatar -
                    </strong>Pe lângă opțiunea de cumpărare cu ridicare din magazin Utilizatorul are opțiunea de livrare la adresa selectată a produselor prin
                    intermediul unui curier/mandatar, colaborator al Romway.</p>
                <p style={{textIndent: "25px"}}>3.16. Astfel, la plasarea comenzii Utilizatorul poate solicita livrarea comenzii prin curier/mandatar la
                    adresa indicată de Utilizator, cu condiția ca acestea să se afle în aria în care Romway activează, localizată-limitată mai sus.</p>
                <p style={{textIndent: "25px"}}>3.17. Utilizatorul este unicul responsabil și direct responsabil pentru indicarea corectă a datelor de
                    contact/de
                    livrare către curier/mandatar. Utilizatorul își asumă întreaga responsabilitate vis-à-vis de eventuale costuri suplimentare, o
                    livrare întârziată sau chiar o imposibilitate de livrare ce are la bază date de contact eronate, incomplete, telefon nefuncțional etc.</p>
                <p style={{textIndent: "25px"}}>3.18. În cazul în care Utilizatorul nu se află la locul indicat pentru livrare sau nu poate fi contactat
                    în
                    niciun mod, adresa indicată eronat, număr telefon indisponibil, etc, comanda se va returna comerciantului de la care a fost achiziționată
                    iar utilizatorul va suporta în integralitate costul transportului.</p>
                <p style={{textIndent: "25px"}}>3.19. Primirea comenzii de către Utilizator la adresa de livrare indicată este considerată acceptare a
                    comenzii.
                    În cazul în care comanda este incompletă sau produsele sunt deteriorate, nu corespund cu ce s-a comandat Utilizatorul va contacta direct
                    Comerciantul furnizor prin mijloacele de contact puse la dispoziție la secțiunea Contact din aplicație în termen de cel mult 1 (una) oră de
                    la primirea comenzii reclamațiile ulterioare nefiind luate în considerare.Utilizatorul are obligația de a înștiința și Romway în cel mult
                    una zi despre toate neregulile ivite în urma plasării unor comenzi.</p>
                <p style={{textIndent: "25px"}}>3.20. Romway și curierul/mandatarul nu își asumă nicio obligație de verificare a identității destinatarului
                    comenzii și o livrare către o altă persoană atâta vreme cât adresa de livare este aceași cu cea indicată de Utilizator.</p>
                <p style={{textIndent: "25px"}}>3.21. Utilizatorul nu va solicita efectuarea unei comenzi care contravine legii și ordinii publice, fie prin
                    conținutul său, fie prin circumstanțele în care este solicitată asumându-și întreaga responsabilitate cu privire la eventuale consecințe
                    contravenționale, administrative, penale, etc ce pot decurge din acțiunile/inacțiunile sale. Romway va colabora activ și va transmite toate
                    informațiile solicitate de autorități în vederea soluționării oricăror incidente cauzate prin utilizarea inadecvată a aplicației, contrară
                    intereselor legale și/sau bunelor-moravuri, având dreptul, fără a fi limitat și fără a fi nevoit să ceară acordul utilizatprului, să
                    transmit autorităților toate datele cu caracter personal declarate de utilizatori la înregistrare sau la plasare unor comenzi.</p>
                <p style={{textIndent: "25px"}}>3.22. Romway își rezerva dreptul de a aplica masuri care pot conduce pana la anularea, suspendarea contului
                    Utilizatorului în aplicație sau restricționarea accesului Utilizatorului in vederea efectuarii unei Comenzi si/sau la unele modalitati de
                    plata acceptate, în situația în care:</p>
                <ul>
                    <li>Utilizatorul încalcă clause din prezentul document;</li>
                    <li>Utilizatorul abuzează de aplicație sau prin acțiunile/inacțiule sale cazuează orice tip prejudiciu site-ului, aplicației, Romway,
                        Comercianților sau Mandatarilor;
                    </li>
                    <li>Exista suspiciuni rezonabile ca Utilizatorul a comis acte frauduloase atunci cand a utilizat aplicația, site-ul.</li>
                    <li>Utilizarea abuzivă a site-ului, a aplicației sau pentru alte scopuri decât cele autorizate expres de Romway este strict interzisă, iar
                        Romway nu este responsabilă pentru nicio pagubă cauzată de o utilizare neautorizata. Astfel, orice utilizare într-un mod care contravine
                        practicilor comerciale corecte, a legislației în vigoare sau în orice alt mod care ar fi de natură să prejudicieze site-ul, aplicația,
                        Romway, Comercianții sau Mandatarii reprezintă utilizarea abuzivă, printre care, nelimitativ, următoarele:
                    </li>
                </ul>
                <p style={{textIndent: "25px"}}>a.
                    nerespectarea
                    obligațiilor de plată sau a oricăror obligații fată de Romway, Comercianți sau Mandatari</p>
                <p style={{textIndent: "25px"}}>b. plasarea unei
                    Comenzi în alte scopuri decât achiziționarea de Produse sau Servicii c. oferirea de
                    informații false, inexacte, neactualizate la crearea Contului, inclusiv indicarea unei identități false, prin orice mijloace (de exemplu
                    prin utilizarea unei adrese de e-mail, nume, pseudonim sau altfel)</p>
                <p style={{textIndent: "25px"}}>d. crearea mai multor
                    conturi
                    de către aceeași persoană fizică sau persoana juridică; e. nerespectarea confidențialității
                    detaliilor de conectare la Cont si lipsa notificării Romway în termen de cel mult una zi când se suspectează preluarea ilegala a accesului
                    la Cont de către un terț f. întreprinderea de acțiuni care sunt ilegale, imorale, împotriva
                    securității publice sau alte actiuni ori omisiuni care încalcă reglementările aplicabile</p>
                <p style={{textIndent: "25px"}}>g. utilizarea oricărui tip
                    de
                    instrument software extern care poate perturba operarea normala a a aplicației și/sau site-ului;</p>
                <p style={{textIndent: "25px"}}>h. cesionarea, copierea,
                    decompilarea sau încercarea de a descoperi codul sursa al aplicației-siteului sau al conținutului acestora;</p>
                <p style={{textIndent: "25px"}}>i. publicarea de conținut
                    care laudă, promovează, încurajează sau incită la terorism, rasism, revizionism, xenofobie, homofobie, sexism, discurs care instiga la ura,
                    discriminare, trafic de persoane, crimă organizată, organizații ilegale, autovătămare, suicid, tortură, cruzime împotriva animalelor,
                    justificarea crimelor de război, exploatarea sexuala a copiilor și/sau a adulților, culturi sau alt Conținut ilegal</p>
                <p style={{textIndent: "25px"}}>j. întreprinderea de
                    acțiuni capabile sa dăuneze intereselor Romway, direct sau indirect, prin orice mod, inclusiv, dar fără a se limita la formularea unor
                    declarații injurioase, îndreptate în mod direct sau indirect, a angajaților, reprezentanților săi, comercianților sau mandatarilor, precum
                    și cu privire la sistemul informatic pus la dispoziție, adoptarea unor poziții publice care pot avea un impact negativ asupra Romway sau
                    serviciilor prestate de aceasta;</p>
                <p style={{textIndent: "25px"}}>3.23. Orice exemple furnizate în cadrul prezentului Document, inclusiv referitoare la tipurile de acțiuni
                    interzise sau măsuri corective, au caracter pur exemplificative iar nu limitativ.</p>
                <p style={{textIndent: "25px"}}>3.24. Pentru livrarea comenzilor prin curier/mandatar utilizatorul va achita, în funcție de comerciant, un
                    cost
                    transport ce va fi menționat la finalizarea comenzii.</p>
                <p style={{textIndent: "25px"}}>3.25. Curierul/Mandatarul care acceptă comanda efectuată de utilizator prin Aplicație se obligă în baza
                    unui mandat să cumpere produsele comandate de Utilizator în numele celui din urmă și conform indicațiilor și specificațiilor acestuia. În
                    cazul în care produsul și/sau serviciul, așa cum a fost descris de către Utilizator în Aplicație, nu este disponibil, Mandatarul are dreptul
                    și obligația de a contacta Utilizatorul pentru a pune în discuție situația ivită și a aduce la cunoștință o alternativă disponibile
                    dacă aceasta există. În cazul în care nu există alte opțiuni disponibile sau dacă Utilizatorul nu acceptă înlocuirea produsului comanda va
                    fi anulată. Dacă Utilizatorul nu poate fi contactat telefonic, nu răspunde la telefon, lipsă semnal sau orice alte imposibilități de
                    contactare și nici nu răspunde ca urmare a contactării fizice la adresa indicată Mandatarul are obligația de a aștepta 10 ( zece)
                    minute după care poate părăsi locația iar comanda plasată și nelivrată va fi anulată. Din prețul achitat online se va reține costul
                    transportului și alte eventuale taxe suplimentare percepute ca urmare a returului iar diferența se va restitui în contul plătitor.</p>
                <p style={{textIndent: "25px"}}>3.26. Serviciile puse la dispoziția Utilizatorilor prin intermediul Aplicației, indiferent de model, vor fi
                    efectuate numai în cazul în care la momentul plății sunt îndeplinite următoarele
                    condiții: - Comerciantul solicitat de către Utilizator trebuie să fie
                    operațional în intervalul orar ales;</p>
                <ul>
                    <li>Produsele achiziționate și care urmează să fie livrate să se afle în aria în care Romway își desfășoară activitatea.</li>
                    <li>Greutatea coletului să nu fie mai mare de 25 KG.</li>
                </ul>
                <ol>
                    <li>
                        <span style={{color: "rgb(0, 0, 0)"}}>Romway nu poate fi tras la raspundere in cazul in care livrarea la domiciliu se realizeaza de catre Parteneri prin mijloacele lor proprii de livrare.</span>
                    </li>
                    <li>
                        <span style={{color: "rgb(0, 0, 0)"}}>Riscul de pierdere sau deteriorare al produselor este transferat Utilizatorului în momentul în care acesta, sau o terță persoană desemnată de acesta, alta decât Mandataru, intră în posesia fizică a produselor. Cu toate acestea, riscul este transferat Utilizatorului în momentul livrării produselor către împuternicitul Utilizatorului, dacă acesta a fost desemnat și împuternicit de către Utilizator să transporte produsele, iar această opțiune nu a fost oferită de către Comerciant.</span>
                    </li>
                </ol>
                <p style={{textIndent: "25px"}}>3.27. Adresa de livrare setata in aplicația Romwa nu poate fi modificata pentru o comanda aflata in curs. In
                    cazul in care Utilizatorul a plasat o comanda la o adresa de livrare eronata sau in cazul in care acesta doreste livrarea la o alta adresa
                    fata de cea trecuta in comanda plasata, acesta va trebui sa anuleze comanda si sa plaseze o noua comanda in aplicatie care va fi onorata
                    exclusiv in masura in care serviciile de livrare ale Romway/Comercianților sunt disponibile la adresa de livrare nou introdusa de
                    Utilizator.</p>

                <p style={{textIndent: "25px"}}>3.28. Metoda de plata ramburs poate fi indisponibilă în anumite situații care vor fi semnalate Utilizatorului
                    în
                    Aplicație, în baza unor motive tehnice sau care țin de politica Comercianților sau a Romway.</p>
                <p style={{textIndent: "25px"}}>
                    <strong>4. PREȚURILE PRODUSELOR. COSTUL TRANSPORTULUI. MODALITĂȚI DE PLATĂ. TIPS. COMISIOANE. FACTURARE</strong>
                </p>
                <p style={{textIndent: "25px"}}>4.1. Toate produsele comercializate prin intermediul site-ului/aplicației au afișate, în conformitate cu
                    prevederile legale, prețurile de achiziție, prețuri furnizate de fiecare comerciant-colaborator în parte. Aceste prețuri pot fi
                    modificate în mod unilateral de către Romway la solicitarea oricărui comerciant înrolat în aplicație utilizatorul neavând niciun drept
                    de a ridica obiecții vis-à-vis de modificarea prețurilor.</p>
                <p style={{textIndent: "25px"}}>4.2. Toate tarifele aferente Produselor și Serviciilor prezentate pe site și în aplicație sunt exprimate in
                    lei
                    (RON) și includ T.V.A.</p>
                <p style={{textIndent: "25px"}}>4.3. În cazul plătilor online Romway și Colaboratorul respectiv Mandatarul nu sunt responsabili și nu pot fi
                    ținuți responabili pentru niciun cost suplimentar achitat de Utilizator, incluzand dar nelimitându-se la comisioanele de conversie valutară
                    aplicate de către banca emitenta a cardului acestuia, în cazul în care moneda de emitere a acestuia diferă de RON. Responsabilitatea pentru
                    aceasta acțiune o poarta numai Utilizatorul</p>
                <p style={{textIndent: "25px"}}>4.4. Accesul Utilizatorilor în aplicație și pe site in vederea creării unui cont este gratuit. Cu toate
                    acestea
                    Romway percepe o taxa de servicii de la Utilizator la plasarea unei comenzi, pentru imbunatatirea permanenta a serviciilor aferente
                    comenzilor plasate: procesare, asistenta, operationale, infrastructura tehnica a aplicației.</p>
                <p style={{textIndent: "25px"}}>
                    <strong>4.5. Costul transportului.</strong>
                    În funcție de distanța la care se efectuează livrarea, în cazul comenzilor cu livrare prin curier/mandatar se va percepe o taxă de transport
                    care va fi stabilită de către Romway și mandatar/curier. Taxa va fi menționată anterior finalizarii și achitării comenzii de către
                    utilizator aceasta având dreptul de a nu plasa și achita comanda.</p>
                <p style={{textIndent: "25px"}}>
                    <strong>4.6. Bacșișul
                    </strong>– valoarea comenzii afișate la finalizarea acesteia nu include nicio sumă oferită cu titlu de tips – bacșiș. Acordarea bacșișului
                    și cuantumul acestuia este stabilit în mod unilateral de către Utilizator și nu este obligatoriu. Aplicația deține opțiunea tips,
                    opțiune care poate fi selectată anterior plasării comenzii și acceptării termenilor – condițiilor. În situația în care utilizator a bifat
                    opțiunea bacșiș și a finalizat comanda respective a achitat comanda acesta nu mai are dreptul de a reveni asupra opțiunii de achitare a
                    bacșișului în mod separat însă, utilizatorul are dreptul de a anula comanda în prezentele termene și condițiile expuse, având posibilitatea
                    de a plasa o nouă comanda fără plata unui bacșiș. Bacșișul este destinat Mandatarului și nu poate fi considerat ca plată pentru serviciile
                    Romway . În această privință, Romway va acționa drept custode și cedent al sumelor care reprezintă bacșișuri. Romway nu incaseaza bacsisul
                    acordat de catre Utilizator Mandatarului, acesta fiind incasat în procent de 100% de catre Mandatar.</p>
                <p style={{textIndent: "25px"}}>4.7. Comercianții-colaboratori au dreptul de renunțare la produsele/serviciile pe care le oferă
                    prin
                    intermediul Aplicației și obligația de a se asigura că prețurile afișate în Aplicație corespund celor disponibile în magazinele
                    fizice.</p>
                <p style={{textIndent: "25px"}}>4.8. Utilizatorul cunoaște că prețurile produselor indicate de comercianți în Aplicație includ prețul
                    ambalajului
                    acestora, care se adaugă la finalul comenzii și se achită odată cu întreaga comandă. În acest paragraf se face referire la sistemul de
                    garanție-returnare denumit în continuare SGR implementat în anul 2023 în România.</p>
                <p style={{textIndent: "25px"}}>4.9. Ulterior plasării comenzii Utilizatorul va primi pe e-mailul asociat contului, o factura pentru Produsele
                    si/sau Serviciile livrate, obligatia Utilizatorului fiind sa furnizeze toate informatiile necesare emiterii facturii conform legislatiei in
                    vigoare.</p>
                <p style={{textIndent: "25px"}}>4.10. Pentru o comunicare corecta a facturii Comenzii, Utilizatorului ii revin obligatiile de a-si actualiza
                    ori
                    de cate ori este cazul datele din Contul sau si de a accesa informatiile si documentele aferente fiecarei Comenzi, existente in Cont.</p>
                <p style={{textIndent: "25px"}}>4.11. Prin trimiterea Comenzii, Utilizatorul isi exprima acordul sa primeasca toate detaliile de facturare in
                    format electronic, pe adresa de e-mail asociata contului de pe care a efectuat Comanda.</p>
                <p style={{textIndent: "25px"}}>4.12. Dacă un Utilizator întâmpină probleme cu plata prin intermediul Platformei de plată pusă la dispoziție
                    în
                    Aplicație, Utilizatorul poate contacta serviciul de Asistență pentru utilizatori Romway prin intermediul Secțiunii de ajutor din Aplicație.
                    Romway va depune toate eforturile pentru a oferi o soluție la această problemă și pentru a asigura legătura cu Platforma de plată în măsura
                    în care acest lucru este posibil; cu toate acestea, Romway nu este responsabilă pentru orice astfel de problemă care intră în activitatea și
                    responsabilitatea Platformei de plată, a băncii sau instituției financiare asimilate a Utilizatorului.</p>
                <p style={{textIndent: "25px"}}>4.13 Utilizatorul poate plăti pentru produse și/sau servicii în numerar la ridicarae comenzii sau prin cardul
                    de
                    debit/credit. La efectuarea comenzii, Utilizatorul va fi informat de diversele opțiuni de plată disponibile</p>
                <p style={{textIndent: "25px"}}>4.14. Pentru a achita cu un card de credit, Utilizatorul trebuie să furnizeze prin Aplicație detaliile
                    cardului
                    drept modalitate de plată asociată contului său. În general, Romway nu stochează numerele de card pe serverele proprii și poate
                    vizualiza doar ultimele patru cifre ale cardului. Informațiile complete sunt stocate pe serverele Platformei de plată prin intermediul
                    căreia se efectuează plățile, Utilizatorul va fi informat, anterior introducerii datelor cu privire la identitatea platformei de plată.</p>
                <p style={{textIndent: "25px"}}>4.15. Utilizatorul poate sa introduca datele mai multor carduri, care se pot salva in Contul sau
                    personal,
                    pentru a eficientiza plata urmatoarelor Comenzi. Totodata Utilizatorul poate sterge oricand doreste orice card salvat.</p>
                <p style={{textIndent: "25px"}}>4.16. In momentul finalizarii platii, Utilizatorul poate acorda voluntar si personal bacsis Mandatarului. In
                    situatia in care Mandatarul pretinde o astfel suma din partea Utilizatorului, acesta nu este tinut sa dea curs solicitarii si recomandam
                    informarea imediata a Romway prin modalitatile puse la dispozitie în aplicație.</p>
                <p style={{textIndent: "25px"}}>
                    <strong>5. ANULAREA COMENZILOR ȘI DREPTUL DE RETRAGERE</strong>
                </p>
                <p style={{textIndent: "25px"}}>5.1. În cazul în care se doreste anularea unei comenzi, Utilizatorul trebuie sa acceseze imediat secțiunea din
                    aplicație privind comenzile mele și să selecteze “Anularea comenzii”. Dacă Comerciantul nu a preluat comanda sau a preluat-o însă acceptă
                    anularea, nu se aplică nicio taxă suplimentară de anulare. Dacă Comerciantul refuză anularea comenzii din cauza faptului că produsele sunt
                    în curs de livrare, au fost preparate, aceasta nu poate fi anulată. Comenzile deja expediate nu vor fi rambursate, cu exceptia situatiei in
                    care exista o culpa dovedita a Romway sau a Comerciantului. In situatia in care comanda expediata este o comanda cu plata ramburs, pentru
                    limitarea prejudiciilor cauzate Comerciantul si a Aplicației metoda de plata ramburs va fi indisponibilă Utilizatorului pentru o perioada de
                    150 de zile.</p>
                <p style={{textIndent: "25px"}}>5.2. În cazul în care anularea a fost solicitată la timp și Comerciantul a acceptat anularea comenzii,
                    Comerciantul va rambursa
                    suma în maximum 14 (paisprezece) zile de la data informării de către Utilizator asupra deciziei sale de retragere din Contract conform
                    prezentului document</p>
                <p style={{textIndent: "25px"}}>5.3. Romway isi rezerva dreptul de a anula o comanda dacă produsul nu este disponibil pentru livrare. In cazul
                    in care
                    comanda anulata este achitata, se va inregistra restituirea sumei.</p>
                <p style={{textIndent: "25px"}}>5.4. Romway poate anula Comanda efectuata de Utilizator, in urma
                    unei notificari prealabile adresate Utilizatorului, fara nicio obligatie ulterioara a vreunei parti fata de cealalta sau fara ca vreo parte
                    sa poata sa pretinda celeilalte daune-interese si in urmatoarele cazuri:</p>
                <ul>
                    <li>neacceptarea de catre banca emitenta a cardului Utilizatorului, a tranzactiei, in cazul platii online;</li>
                    <li>invalidarea tranzactiei de catre posesorul de cardul agreat de Romway, in cazul platii online;</li>
                    <li>datele furnizate de catre Utilizator pe website sau aplicatie sunt incomplete si/sau incorecte;</li>
                </ul>
                <p style={{textIndent: "25px"}}>5.5. Anularea comenzilor de către Romway. Romway își rezervă dreptul de a anula o comandă fără a fi necesar
                    să
                    furnizeze un motiv întemeiat. Romway va informa Utilizatorul despre anularea menționată și, în cazul unei anulări fără motiv întemeiat
                    la
                    inițiativa Romway, orice sumă reținută în contul și/sau pe cardul de credit al Utilizatorului va fi deblocată.
                </p>
                <p style={{textIndent: "25px"}}><strong>5.6. Dreptul de retragere. Returnarea produselor</strong>. În conformitate cu prevederile legale în
                    vigoare, Utilizatorul își
                    poate
                    exercita dreptul de retragere, respectiv să returneze un produs comandat prin intermediul Aplicației sau sa renunte la un serviciu, fara
                    invocarea niciunui motiv, in termen de 14 zile calendaristice:</p>
                <ul>
                    <li>de la data incheierii contractului, in cazul prestarii de servicii,</li>
                    <li>din ziua intrarii în posesia fizică a respectivului produs,</li>
                    <li>din ziua în care Utilizatorul intră în posesia fizică a ultimului produs– în cazul în care printr-o singură comandă sunt comandate
                        produse multiple care vor fi livrate separat;
                    </li>
                    <li>ziua în care Utillizatorul intră în posesia fizică a ultimului produs sau a ultimei piese – în cazul livrării unui produs
                        care constă din mai multe loturi sau piese.
                    </li>
                </ul>
                <p style={{textIndent: "25px"}}>5.7. Dacă după livrare se constată existența unor produse lipsă, neconforme sau
                    deteriorate
                    vă rugam să notificati Romway prin modalitatile puse la dispozitie prin aplicație. In cazul lipsei conformitatii Produselor
                    livrate, puteți solicita înlocuirea produsului sau contravaloarea acestuia. Înlocuirea produsului este posibila doar în limita
                    politicilor fiecărui Comerciant Luand in considerare normele legale, returnarea nu este acceptata pentru alimentele gatite sau
                    pentru
                    produsele susceptibile a se deteriora sau expira rapid.</p>

                <p style={{textIndent: "25px"}}>5.8. Pentru a putea evalua gravitatea situației, va fi necesar să ne transmiteți dovada neconformității
                    (poze,
                    înregistrări video, etc.) și dovada faptului ca pachetele au sosit sigilate. În funcție de gravitatea situației și dacă se va constata
                    că
                    reclamația dumneavoastră este întemeiată veți avea dreptul, după caz, la înlocuirea Produsului (acolo unde este posibil) sau rambursarea
                    totală sau parțială a contravalorii Comenzii.</p>
                <p style={{textIndent: "25px"}}>5.9. Utilizatorii sunt informați cu privire la faptul că, în toate cazurile, rolul Romway este acela de
                    intermediar și că, în orice situație, în cazul in care Utilizatorul doreste sa solicite retragerea din contract, acesta trebuie sa
                    urmeze
                    pasii prevazuti in politicile de retur ale Comercianților. Asadar, dreptul de retragere va fi supus politicii de retur a Comerciantului,
                    iar
                    decizia privind oportunitatea returnării va aparține Comerciantului, și nu a Romway, în fiecare caz. In acest sens, Utilizatorii
                    trebuie să consulte politica proprie de retur a Comerciantului care a vândut produsul respectiv sau a prestat serviciul.</p>
                <p style={{textIndent: "25px"}}>5.10.
                    <strong>Potrivit prevederilor legale in vigoare, sunt exceptate de la dreptul de retragere următoarele categorii de produse</strong>:
                </p>
                <ul>
                    <li>produse susceptibile de a se deteriora rapid sau a expira rapid, cum ar fi alimentele gătite, fructele, legumele, produse lactate,
                        produse de cofetărie, orice tipuri de carne, fără ca aceasta enumerare să fie limitativă;
                    </li>
                    <li>produse sigilate care nu pot fi returnate din motive de protecție a sănătății sau din motive de igienă și care au fost desigilate de
                        Utilizator;
                    </li>
                    <li>produse și/sau servicii al căror preț depinde de fluctuațiile de pe piața financiară pe care Comerciantul nu le poate controla și
                        care
                        pot avea loc pe parcursul perioadei de retragere;
                    </li>
                    <li>produse care sunt, după livrare, potrivit naturii acestora, inseparabil amestecate cu alte elemente;</li>
                    <li>• înregistrări audio sau video sigilate sau de programe informatice sigilate care au fost desigilate după livrare;</li>
                    <li>ziare, periodice și reviste, cu excepția contractelor de abonament pentru furnizarea de astfel de publicații;</li>
                </ul>
                <p style={{textIndent: "25px"}}>5.11. Romway nu poate fi tinuta raspunzatoare si nu poate suporta plata retururilor cauzate din varii
                    motive.
                    In
                    aceste cazuri, obligatiile Romway se limiteaza la: 1). Publica, gazduieste si promoveaza ofertele Comercianților in aplicație; 2).
                    Faciliteaza incheierea Contractului la distanta dintre Utilizator si Comerciant si 3). Transmite comenzile catre Comerciant.</p>
                <p style={{textIndent: "25px"}}>
                    <strong>6. POLITICA PRIVIND LIVRAREA PRODUSELOR INTERZISE</strong>
                </p>
                <p style={{textIndent: "25px"}}>
                    6.1. Această secțiune prezintă o listă incompletă de exemple de elemente care pot fi limitate sau restricționate din punct de vedere
                    juridic anume:</p>
                <ul>
                    <li>Produse alcoolice și de tutun Alcoolul și produsele din tutun, precum și orice alte produse conexe și/sau asimilate.</li>
                    <li>Animale și specii reglementate Părți de animale sau fluide, semințe interzise, plante periculoase, plante reglementate sau alte
                        organisme (inclusiv produsele lor secundare) care sunt în pericol sau a căror comercializare este, după caz, reglementată.
                    </li>
                    <li>Pornografie infantilă Materialele pornografice ce implică copii sau conținut ce ar putea fi perceput ca pedofilie erotică.</li>
                    <li>Produsele contrafăcute și neautorizate Copiile sau imitațiile unor produse de designer sau alte bunuri, aparținând unor celebrități
                        care
                        în general ar necesita existența unei autentificări, autografe false, monedă falsă, ștampile, bilete sau bunurile neautorizate.
                    </li>
                    <li>Dispozitive sau instrumente pentru eliminarea măsurilor de securitate Modemurile, cip-uri sau alte dispozitive pentru demontarea
                        măsurile tehnice de protecție, precum și dispozitivele digitale, inclusiv pentru deblocarea iPhone.
                    </li>
                    <li>Droguri, substanțe controlate, stupefiante, droguri ilegale și accesorii ale acestora, inclusiv droguri psiho-active și vegetale,
                        cum ar
                        fi ciupercile psihedelice, precum și materiale care promovează utilizarea acestora.
                    </li>
                    <li>Explozibili și altele similare Explozivi, substanțe inflamabile, corozive, substanțe toxice</li>
                    <li>Jocuri de noroc și pariuri Bilete de loterie, pariuri, aderări/înregistrări ale site-urilor de pariuri online și conținut conex.
                        Promovarea permisă a cazinourilor fizice.
                    </li>
                    <li>Dispozitive pirotehnice și substanțe periculoase care necesită permisiune specială</li>
                    <li>Articole pirotehnice și mărfuri aferente pe piețele în care este reglementată furnizarea acestora, precum și substanțe precum
                        benzină
                        sau propan.
                    </li>
                    <li>Arme, Arme de foc, muniție și alte articole, inclusiv, fără limitare, arme de foc, cuțite care pot fi nedetectabile sau ușor de
                        ascuns,
                        arme de arte marțiale, amortizoare, muniții sau reviste de muniție.
                    </li>
                </ul>
                <p style={{textIndent: "25px"}}>6.2. Ne rezervăm dreptul de a refuza comenzile utilizatorilor care sunt minori. În plus, ne rezervăm dreptul de
                    a refuza comenzile din
                    orice
                    locație din apropierea unei școli primare sau gimnaziale, precum și de a solicita o dovadă cu privire la vârsta utilizatorului.
                </p>
                <p style={{textIndent: "25px"}}>6.3. Considerații specifice privind achizițiile de alcool<strong>.
                </strong>Utilizatorii care acceptă acești Termeni și condiții pot vizualizași plasa comenzi care implică
                    achiziționarea și/sau livrarea de produse de tutun prin intermediul Platformei Groovy Cart. Prin apăsarea expresă a căsuței de selectare,
                    Utilizatorul acceptă înmod explicit că are vârsta legală prevăzută și permisă delegislația aplicabilă peteritoriul în care este plasată
                    comanda. În orice caz, Groovy Cart își rezervă dreptul de a nu procesa / livra nici o comandă, inclusiv produse de tutun, acelor destinatari
                    ai comenzii care nu pot dovedi că sunt Utilizatorul identificat pe Platforma Groovy Cart, precum și că are vârsta legală sau alte restricții
                    prevăzute de legislația aplicabilă pe teritoriul în care Utilizatorul plasează comanda.Ca oconsecință acelor demai sus, Utilizatorul va
                    suporta costul integralal produsului și costurile asociate cu plasarea și anularea comenzii. În acele orașe în care vânzarea și/sau livrarea
                    de produse de tutun este restricționată în anumite ore, Utilizatorul trebuie să respecte utilizarea corectă a Platformei și, în
                    consecință,să respecte intervalul de ore interzise de legislația aplicabilă. În orice caz, Groovy Cart își rezervă dreptul de a refuza
                    procesarea/livrarea comenzii, inclusiv a tutunului, în afara orelor permise. Drept urmare, Utilizatorul va suporta costul integral al
                    produsului și costurile asociate cu plasarea și anularea comenzii. În cazul în care Utilizatorul își transferă contul Groovy Cart către
                    persoane minore sau persoane care, datorită circumstanțelor proprii, se pot afla în situații care necesită o protecție specială, Groovy Cart
                    își rezervă dreptul de a iniția toate acțiunile civile/penale pentru a proteja drepturile persoanelor mai vulnerabile. În acest sens, Groovy
                    Cart se angajează său tilizeze toate mijloacele materiale și umane pentrua controla acest tip de fraudă,dar în nici un caz nu este
                    responsabilă pentru transferul ilegalal unui cont către o altăpersoană care nu a acceptat în mod expres acești Termeni și Condiții. Gtoovy
                    Cart nu recomandă și/sau nu promovează în niciun fel consumul nici unui a dintre produsele din tutun achiziționate.
                </p>
                <p style={{textIndent: "25px"}}>6.4. Romway își rezervă dreptul de a nu procesa/livra nicio comandă, inclusiv alcool, acelor destinatari ai
                    comenzii care nu pot dovedi
                    că
                    sunt Utilizatori identificați în Aplicație precum și că au vârsta legală, minim 18 ani împliniți. Ca o consecință a celor de mai
                    sus,
                    Utilizatorul va suporta costul integral al produsului și costurile asociate cu plasarea și anularea comenzii.</p>
                <p style={{textIndent: "25px"}}>6.5. Utilizatorul
                    trebuie
                    să respecte utilizarea corectă a Aplicației și, în consecință, să respecte intervalul orar de activitate al fiecărui
                    comerciant.
                </p>

                <p style={{textIndent: "25px"}}>7. <strong>GEOLOCAȚIE</strong></p>
                <p style={{textIndent: "25px"}}>Romway poate colecta, utiliza și partaja date privind localizarea exactă, inclusiv locația geografică în
                    timp
                    real a computerului sau dispozitivului mobil al Utilizatorului, cu condiția să existe o autorizare din partea Utilizatorului.
                    Respectivele
                    date privind locația pot fi colectate și utilizate de Romway pentru a indica Utilizatorilor locația punctului de pornire și/sau
                    destinația
                    comenzii. În acest sens, Utilizatorii își dau în mod expres consimțământul pentru ca datele lor de locație să fie partajate cu orice
                    Comerciant și Mandatar, pentru a executa cu succes comanda solicitată la un moment dat. Utilizatorii pot alege să dezactiveze Geolocația
                    de
                    pe dispozitivul mobil al acestora, astfel cum se prevede în Politica de confidențialitate a datelor.</p>
                <p style={{textIndent: "25px"}}>
                    <strong>8.</strong>
                    <strong>RESPONSABILITĂȚI ȘI GARANȚII</strong>
                </p>
                <p style={{textIndent: "25px"}}>8.1. Utilizatorii sunt responsabili pentru deținerea serviciilor și echipamentelor necesare pentru navigare
                    pe
                    Internet și accesarea Aplicației.</p>
                <p style={{textIndent: "25px"}}>8.2. Romway nu își asumă nicio raspundere referitoare la executarea Contractului, acesta avand rol de
                    intermediar în vânzarea produselor/serviciilor Comercianților.</p>
                <p style={{textIndent: "25px"}}>8.3. Prin crearea si utilizarea Contului, Utilizatorul isi asuma raspunderea pentru mentinerea
                    confidentialitatii
                    datelor de Cont (user si parola) si pentru gestionarea accesarii Contului, si in masura permisa de legislatia in vigoare, este
                    responsabil
                    de activitatea derulata prin intermediul Contului sau și acceptă în mod expres prezentele termene și condiții.</p>
                <p style={{textIndent: "25px"}}>8.4. Romway isi rezerva dreptul de a actualiza si modifica periodic Termenele si Conditiile pentru a
                    reflecta
                    orice modificari ale modului si conditiilor de functionare a Aplicației sau orice modificari ale cerintelor legale. Documentul este
                    opozabil
                    Utilizatorilor de la momentul afisarii in Platforma. In cazul oricarei astfel de modificari, vom afisa pe Platforma versiunea modificata
                    a
                    Documentului, motiv pentru care va rugam sa verificati periodic continutul acestui Document.</p>
                <p style={{textIndent: "25px"}}>8.5 Informațiile publicate pe site-ul {website}, respectiv în
                    aplicație referitoare la produsele și serviciile de
                    care
                    puteți beneficia precum și prețurile acestora, ne sunt furnizate de către Comercianții, Mandatari aceștia fiind singurii responsabili de
                    realitatea, acuratețea, calitatea și cantitatea lor. Conceperea, redactarea, precum și furnizarea corectă, actuală și exactă a
                    informațiilor
                    este responsabilitatea fiecărui Comerciant în parte.</p>
                <p style={{textIndent: "25px"}}>8.6 Societatea nu poartă nici o responsabilitate în ceea ce privește calitatea produselor și serviciilor
                    oferite
                    de Comercianți și a căror informații sunt găzduite pe pe site și în aplicație. Orice produse vizualizate, comandate și cumpărate prin
                    intermediul Romway, respectiv orice servicii conexe, sunt garantate de către Comercianți.</p>
                <p style={{textIndent: "25px"}}>8.7 Romway este răspunzătoare fata de Utilizator pentru orice prejudicii cauzate de nelivrarea sau
                    intarzierea
                    livrarii comenzii, daca nu pot invoca o cauza de inlaturare a raspunderii.8.8 Romway nu garantează absența erorilor sau întreruperilor
                    Aplicației sau a conținutului acesteia, sau că va fi întotdeauna actualizată și nu va fi responsabilă pentru vreo întrerupere de
                    serviciu,
                    erori de conexiune, indisponibilitate sau erori, acces serviciu Internet sau întreruperi Internet sau pentru orice alte situații în
                    afara
                    controlului său. În situația oricărei erori vom depune eforutir considerabile pentru remedierea acestora.</p>
                <p style={{textIndent: "25px"}}>8.9. Romway nu este responsabilă pentru prejudiciile cauzate Utilizatorilor de erorile, neacuratețea sau
                    neactualizarea informațiilor publicate sau menținute în aplicație, care nu se datorează culpei sale, această responsabilitate revenind,
                    în
                    integralitate Comercianților care au obligația de a-și actualiza permanent informațiile referitoare la produse și/sau serviciile conexe
                    oferite.8.10. Romway nu poate raspunde pentru indisponibilitatea website-ului si/sau aplicatiei din motive independente de eforturile
                    depuse
                    pentru remedierea problemelor tehnice.</p>
                <p style={{textIndent: "25px"}}>8.11. Romway nu răspunde pentru comportamentul pe site și în aplicație al
                    Utilizatorilor/Comercianților/Mandatarilor sau a oricăror altor persoane, pentru conținutul adăugat de aceștia în aplicație și pe site
                    sau
                    pentru executarea necorespunzătoare a contractelor și tranzacțiilor încheiate prin intermediul aplicației, nici pentru consecințe,
                    atunci
                    când aceștia încalcă prevederile prezentului Document și reglementările aplicabile.</p>
                <p style={{textIndent: "25px"}}>8.12. În calitate de intermediar, Romway doar facilitează, prin intermediul Aplicației, mijloacele prin care
                    părțile implicate (adică Comercianții, Utilizatorii și Mandatarii) pot intra în contact între ele pentru a aranja vânzarea și, dacă se
                    dorește, livrarea produselor și serviciilor la adresa indicată de Utilizator. Prin urmare, Romway nu este în nici un fel responsabilă și
                    nu
                    garantează și nu își asumă nici o răspundere pentru timpii de livrare, pentr lipsă livrare, livrare incomplete, pentru comportamentul
                    Mandatarului, pentru deteriorarea sau degradarea bunurilor pentru produsele sau serviciile oferite de Comercianți sau pentru furnizarea
                    oricăror servicii de livrare solicitate de către Mandatari. Romway nu va verifica și nu este responsabilă pentru verificarea produselor
                    trimise sau livrate prin intermediul comenzilor din Aplicație.</p>
                <p style={{textIndent: "25px"}}>8.13. Toți Comercianții și Mandatarii au obligația să respecte legislația în domeniu aplicabilă și cele
                    mai
                    înalte standarde de sănătate și siguranță în pregătirea și manipularea produselor, precum și în furnizarea de informații către
                    Utilizatori
                    și consumatori. În plus, având în vedere că orice produs alimentar oferit prin intermediul Aplicației este pregătit și ambalat în
                    unitățile
                    Comerciantului, Romway nu este responsabilă pentru a se asigura că comenzile alimentare sunt livrate Utilizatorilor în ambalaje închise
                    corespunzător.</p>
                <p style={{textIndent: "25px"}}>8.14. Utilizatorii, Comercianții și Mandatarii, exonerează Romway de orice răspundere care ar putea
                    apărea
                    din cauza disponibilității și/sau transportului de produse care necesită autorizații sau licențe specifice, sau de produse interzise de
                    orice legislație aplicabilă.</p>
                <p style={{textIndent: "25px"}}>8.15. Romway, în calitate de intermediară între Utilizator și Mandatar, poate să medieze, în măsura în care
                    este
                    posibil litigiul ivit între Utilizator și Mandatar în timpul livrării și/sau gestionării bunurilor prin intermediul Aplicației cu
                    condiția
                    ca Utilizatorul să înștiințeze Romway prin mijloacele de contact existente în aplicație despre incident, în cel mai scurt timp posibil
                    dar
                    nu mai târziu de 48 h de la incident.</p>
                <p style={{textIndent: "25px"}}>
                    <strong>9.PRELUCRAREA DATELOR CU CARACTER PERSONAL SI CONFIDENTIALITATEA INFORMATIILOR</strong>
                </p>
                <p style={{textIndent: "25px"}}>9.1 Pentru a putea beneficia de serviciile noastre de comenzi online, este necesara completarea datelor
                    personale
                    precum: nume si prenume, adresa de livrare, adresa de facturare, adresa de e-mail, numarul de telefon, etc.</p>
                <p style={{textIndent: "25px"}}>9.2 Scopul colectării și prelucrării datelor cu caracter personal este în principal preluarea și procesarea
                    comenzilor online, informarea Utilizatorilor / Partenerilor privind evoluția și statusul fiecărei comenzi precum si informarea
                    Utilizatorilor cu privire la situatia contului personal.</p>
                <p style={{textIndent: "25px"}}>9.3 De asemenea, colectarea datelor personale ajuta la identificarea Utilizatorului in cazul participarii la
                    concursuri, castigarii anumitor premii precum si realizarea de rapoarte statistice.</p>
                <p style={{textIndent: "25px"}}>9.4 Datele colectate și stocate de către compania noastră sunt confidențiale. Noi prelucrăm datele personale
                    împreună cu partenerii noștri în calitate de operatori asociați, strict pentru scopurile noastre comune respectiv: prelucrarea
                    comenzilor,
                    livrarea produselor către dumneavoastră, executarea obligațiilor de garanție, asistență și suport clienți.</p>
                <p style={{textIndent: "25px"}}>9.5 Datele prelucrate de noi sunt cele furnizate de către dumneavoastră prin completarea lor în formularul
                    de
                    creare de cont și prin care acceptați să fie incluse în baza de date a Aplicației Romway. Datele sunt înregistrate numai în momentul în
                    care
                    dumneavoastră sunteți de acord și sunteți informați în privința faptului că acestea vor fi transmise către noi.</p>
                <p style={{textIndent: "25px"}}>9.6 Destinatarii către care se pot transmite datele personale colectate sunt furnizorii implicați în mod
                    direct/indirect în procesul de execuție a comenzii plasate: angajați, comercianți, dispeceri, curieri / firme de curierat, procesatori
                    de
                    plăți online, sau, după caz, autorități ale statului (inclusiv autorități fiscale, autorități de protecție a consumatorilor, organele
                    statului competente în materie penală), ca urmare a unei obligații legale a Societății;</p>
                <p style={{textIndent: "25px"}}>9.7 Prin clauze speciale stipulate în fiecare contract de parteneriat (cu comercianți, dispeceri, curierii /
                    firmele de curierat, etc) ne asigurăm de obligativitatea respectării confidențialității datelor personale la care Partenerii noștri au
                    acces
                    prin intermediul relației contractuale cu Romway, precum și alinierea lor la normele naționale și internaționale referitoare la
                    prelucrarea
                    datelor personale cu fiecare dintre aceștia.</p>
                <p style={{textIndent: "25px"}}>9.8 Referitor la datele personale, aplicăm un cadru de politici și standarde minime la toate nivelurile
                    Societății noastre pentru a păstra siguranța datelor dvs. Aceste politici și standarde sunt actualizate periodic pentru a corespunde
                    reglementărilor și legislației actuale. În mod specific și conform legii, luăm măsuri tehnice și organizatorice adecvate (politici și
                    proceduri, securitate informatică etc.) pentru a sigura confidențialitatea și integritatea datelor dvs. cu caracter personal și a
                    modului în
                    care sunt prelucrate. În plus, angajații Societății au obligația contractuală de a păstra confidențialitatea și nu pot divulga datele
                    dvs.
                    cu caracter personal în mod ilegal sau inutil.</p>
                <p style={{textIndent: "25px"}}>9.9 Oricărui Utilizator care este înregistrat pe site și în aplicație, îi sunt garantate drepturile
                    prevăzute
                    de
                    lege, respectiv dreptul de informare, dreptul de acces la date, dreptul de intervenție, dreptul de opoziție, dreptul de a se opune
                    prelucrării datelor personale și de a solicita ștergerea totală sau parțială a acestora, dreptul la portabilitatea datelor, dreptul de
                    se
                    adresa justiției în caz de încălcare a drepturilor garantate și reglementate prin legislația internă și prin norme europene.</p>
                <p style={{textIndent: "25px"}}>9.10 În vederea exercitării acestor drepturi, trebuie să vă adresați cu o solicitare către: SC ROMWAY TRADE SRL
                    , Judet BIHOR, sau pe adresa de e-mail: office@brutariaadoni.com 9.11 Pentru oricare dintre solicitările primite referitor la prelucrarea
                    datelor personale, societatea vă va comunica măsurile luate în temeiul celor de mai sus, precum şi, dacă este cazul, numele terţului căruia
                    i-au fost dezvăluite datele cu caracter personal referitoare la dumneavoastră, în termen de 30 zile de la data primirii cererii.</p>
                <p style={{textIndent: "25px"}}>9.12 Prelucrarea datelor minorilor pe bază de consimțământ nu poate fi efectuată întrucât orice Utilizator
                    trebuie să aibe vârsta de minim 18 ani împliniți.</p>
                <p style={{textIndent: "25px"}}>
                    <strong>10.</strong>
                    <strong>DREPTUL DE PROPRIETATE INTELECTUALĂ</strong>
                </p>
                <p style={{textIndent: "25px"}}>10.1. Continutul aplicației nelimitandu-se la logo-uri, reprezentari, simboluri comerciale, imagini
                    statice, imagini dinamice, text si/sau continut multimedia prezentate pe website si/sau pe aplicatie, sunt proprietatea exclusiva a
                    Romway,
                    acestuia fiindu-i rezervate toate drepturile obtinute in acest sens in mod direct sau indirect (prin licente de utilizate si/sau
                    publicare).10.2. Utilizatorului nu ii este permisa copierea, distribuirea, publicarea, transferul catre terte parti, modificarea si/sau
                    altfel alterarea, utilizarea, legarea la, expunerea, includerea oricarui Continut in orice alt context decat cel original intentionat de
                    Romway, includerea oricarui Continut in afara Aplicației, indepartarea insemnelor care semnifica dreptul de autor al Romway asupra
                    Continutului precum si participarea la transferul, vanzarea, distributia unor materiale realizate prin reproducerea, modificarea sau
                    afisarea Continutului, decat cu acordul scris expres al Romway.</p>
                <p style={{textIndent: "25px"}}>10.3. Orice Continut la care Utilizatorul are si/sau obtine acces prin orice mijloc, se afla sub incidenta
                    Documentului, in cazul in care Continutul nu este insotit de un acord de utilizare specific si valid incheiat intre Romway, si acesta si
                    fara nicio garantie implicit sau expres formulata din partea Romway, cu referire la acel Continut.</p>
                <p style={{textIndent: "25px"}}>10.4. Utilizatorul poate copia, transfera si/sau utiliza Continut numai in scopuri personale sau
                    non-comerciale,
                    numai in cazul in care acestea nu intra in conflict cu prevederile Documentului.</p>
                <p style={{textIndent: "25px"}}>
                    <strong>11.</strong>
                    <strong>CLAUZA DE SEPARABILITATE</strong>
                    - În măsura în care o instanță sau o autoritate competentă constată că o clauză sau mai multe din prezentul document este considerate
                    ilegală, anulabilă sau nulă prin aplicarea legii, se va considera că respectiva clauză nu face parte din document. O astfel de
                    declarare a nulității nu va afecta în niciun mod valabilitatea celorlalte clauze.</p>
                <p style={{textIndent: "25px"}}>
                    <strong>12. DIVERSE. LITIGII.FORȚA MAJORĂ</strong>
                </p>
                <p style={{textIndent: "25px"}}>12.1. Pentru a facilita comunicarea cu Mandatarul și, dacă este necesar, în eventualitatea oricăror
                    incidente
                    în
                    procesarea comenzii și/sau pentru a informa cu privire la orice modificări, Romway pune la dispoziția Utilizatorilor, prin secțiunea
                    proprie
                    de Ajutor din Aplicație, un sistem intern de mesagerie chat care permite contactul direct și imediat între Utilizator și Mandatar pe
                    parcursul îndeplinirii comenzii. În cazul în care un Utilizator dorește să contacteze Mandatarul sau Romway după finalizarea comenzii,
                    acesta trebuie să utilizeze Secțiunea de Ajutor disponibilă în Aplicație iar Romway va acționa ca intermediar pentru soluționarea
                    oricăror
                    reclamații legate de comanda plasată de către Utilizator, de contul Utilizatorului și/sau de orice alte politici și/sau servicii
                    furnizate
                    prin intermediul Aplicației. În acest scop, Utilizatorul va contacta Romway prin intermediul secțiunii de Ajutor din Aplicație, la
                    numărul
                    de telefon {phoneNumber} sau la adresa de e-mail {primaryEmail}.</p>
                <p style={{textIndent: "25px"}}>12.2. Utilizatorii aplicatiei pot solicita rezolvarea unor situatii considerate de catre acestia drept
                    abateri de la conditiile legale sau contractuale de furnizare a serviciilor.</p>
                <p style={{textIndent: "25px"}}>12.3. Romway depune eforturi in rezolvarea tuturor sesizarilor si reclamatiilor privind produsele si/sau
                    serviciile listate în Aplicație, , funcționalitățile acesetia sau Conținutul ilegal intr-un termen rezonabil, de aceea va rugam ca in
                    cazul
                    in care doriti sa faceti o sesizare sau sa reclamati un anume aspect care este in dezacord cu asteptarile dumneavoastra, sa ne scrieti
                    la
                    adresa {primaryEmail}, specificand subiectul reclamat si detaliile referitoare la acesta, precum si datele dumneavoastra de
                    contact.</p>
                <p style={{textIndent: "25px"}}>12.4. Romway va informa pe cel care a făcut o sesizare sau reclamație cu privire la acceptarea acesteia și
                    acolo
                    unde este cazul cu privire la decizia luată, cu condiția să i se fi furnizat datele de contact.</p>

                <p style={{textIndent: "25px"}}>12.5. Termenul de raspuns este de maximum
                    15
                    zile lucratoare de la data receptionarii sesizării sau reclamatiei.</p>
                <p style={{textIndent: "25px"}}>12.6. Legea aplicabilă tuturor raporturilor este legea română.</p>
                <p style={{textIndent: "25px"}}>12.7. În situația în care litigiul ivit nu se soluționează pe cale amiabilă oricare parte se poate adresa
                    instanțelor competente în baza legii;</p>
                <p style={{textIndent: "25px"}}>12.8. Dreptul Utilizatorilor/oricaror persoane interesate care au transmis notificari catre Romway de a
                    opta pentru orice organism de soluționare extrajudiciară a litigiilor, nu aduce atingere dreptului acestora de a iniția, în orice etapă,
                    proceduri pentru a contesta în fața unei instanțe judecătorești, în conformitate cu prevederile legale în vigoare.</p>
                <p style={{textIndent: "25px"}}>12.9. Nici una din parti nu va fi raspunzatoare pentru neexecutarea obligatiilor sale contractuale, daca o
                    astfel
                    de neexecutare la termen si/sau in mod corespunzator, total sau partial este datorata unui eveniment de forta majora. Forta majora este
                    evenimentul imprevizibil, in afara controlului partilor si care nu poate fi evitat.</p>
                <p style={{textIndent: "25px"}}>12.10. Daca in termen de 15 (cincisprezece) zile de la data producerii lui, respectivul eveniment nu
                    inceteaza,
                    fiecare parte va avea dreptul sa notifice celeilalte parti incetarea de plin drept a contractului fara ca vreuna dintre ele sa poata
                    pretinde celeilalte alte dauna-interese.</p>
                <p style={{textIndent: "25px"}}>

                    <strong>Alegând să nu acceptați Termenii și Condițiile Generale vom fi în imposibilitatea de a vă oferi serviciile noastre, deoarece
                        întregul proces are la bază informațiile colectate de la dumneavoastră, fără de care nu se poate onora comanda dumneavoastră
                        online.</strong>
                </p>


                <h4 style={{textAlign: 'center'}}>
                    13. MODIFICAREA TERMENILOR ȘI CONDIȚIILOR
                </h4>
                <p style={{textIndent: "25px"}}>
                    13.1. Romway își rezervă dreptul de a modifica în orice moment prezentul Document, fără notificare
                    prealabilă a Utilizatorilor. Modificările vor fi valabile pentru Utilizatori de la data afișării pe
                    site și în aplicație. Utilizatorii sunt responsabili să consulte periodic Documentul pentru a lua la
                    cunoștință de orice modificări.
                </p>
                <p style={{textIndent: "25px"}}>
                    13.2. În cazul în care Utilizatorul nu este de acord cu modificările aduse Documentului, acesta are
                    dreptul de a-și închide contul și de a nu mai folosi serviciile oferite de Romway.
                </p>
                <p style={{textIndent: "25px"}}>
                    13.3. Utilizarea în continuare a Aplicației după modificarea Documentului va fi considerată
                    acceptarea modificărilor aduse Documentului.
                </p>
                <p style={{textIndent: "25px"}}>
                    <b>
                        Alegând să nu acceptați Termenii și Condițiile Generale vom fi în imposibilitatea de a vă oferi
                        serviciile noastre, deoarece întregul proces are la bază informațiile colectate de la dumneavoastră,
                        fără de care nu se poate onora comanda dumneavoastră online.
                    </b>
                </p>

            </div>
            <Copyright sx={{mt: 8, mb: 4}}/>
        </>
    );
};

export default TermsAndConditions;
