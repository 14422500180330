import React from 'react';
import Copyright from "../components/Copyright";
import {Avatar, Box, Button, Container, createTheme, CssBaseline, TextField, ThemeProvider} from "@mui/material";
import Typography from "@mui/material/Typography";
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import {login} from "../api/init";
import {toast} from "react-toastify";
import {deleteProfile} from "../api/my";

const theme = createTheme({
    palette: {
        primary: {
            main: '#f44336',
        },
        text: {
            primary: '#FFFFFF',
        },
        background: {
            default: '#FFA500',
        },
    },
});
const DeleteMe = () => {
    const [success, setSuccess] = React.useState<boolean>(false);

    const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();

        const data = new FormData(event.currentTarget);

        const email = data.get("email")?.toString();
        const password = data.get("password")?.toString();
        console.log(email);
        console.log(password);
        if (email && email.length > 0 && password && password.length > 0) {
            let tokenResponse = await login(email, password);
            if (tokenResponse && tokenResponse.access_token) {
                if (await deleteProfile(tokenResponse.access_token)) {
                    toast.success("Profile deleted.")
                    setSuccess(true);
                }
            }
        } else {
            toast.error("Email and password are required.")
        }
    }

    function deleteProfileForm() {
        return <Box
            sx={{
                marginTop: 8,
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
            }}
        >
            <Avatar sx={{m: 1, bgcolor: 'primary.main'}}>
                <DeleteForeverIcon/>
            </Avatar>
            <Typography component="h1" variant="h5">
                Delete GroovyCart account
            </Typography>
            <Box component="form" onSubmit={handleSubmit} noValidate sx={{mt: 1}}>
                <TextField
                    margin="normal"
                    required
                    fullWidth
                    id="email"
                    label="Email"
                    name="email"
                    autoFocus
                />
                <TextField
                    margin="normal"
                    required
                    fullWidth
                    name="password"
                    label="Password"
                    type="password"
                    id="password"
                />
                <Button
                    type="submit"
                    fullWidth
                    variant="contained"
                    sx={{mt: 3, mb: 2}}
                >
                    Delete account
                </Button>
            </Box>
        </Box>;
    }

    function profileDeletedBox() {
        return <Box
            sx={{
                marginTop: 8,
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
            }}
        >
            <Avatar sx={{m: 1, bgcolor: 'primary.main'}}>
                <CheckCircleIcon/>
            </Avatar>
            <Typography component="h1" variant="h5">
                Profile successfuly deleted
            </Typography>
        </Box>;
    }

    return (
        <ThemeProvider theme={theme}>
            <Container component="main" maxWidth="xs">
                <CssBaseline/>
                {
                    !success ? deleteProfileForm() : profileDeletedBox()
                }
                <Copyright sx={{mt: 8, mb: 4}}/>
            </Container>
        </ThemeProvider>
    );
};

export default DeleteMe;
