import {toast} from "react-toastify";

const handleError = (error: Error) => {
    toast.error("Error occurred, contact administrator.")
    console.error('An error occurred:', error);
};

const handleErrorBoolean = (error: Error) => {
    toast.error("Error occurred, contact administrator.")
    console.error('An error occurred:', error);
    return false
};


export {handleError, handleErrorBoolean}